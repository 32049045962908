<!-- System: STA
    Purpose: User can update his/her Tasks/Activities
            status by this component
-->

<template>
  <!-- Modal For Task Update -->
  <v-dialog
    v-model="updateTaskModal"
    @click:outside="onHidden"
    width="500"
    height="400"
  >
    <v-card class="update-task-modal" id="update" title="New Task">
      <v-card-title class="headline white lighten-2 justify-center">
        Update Task
        <v-spacer></v-spacer>
        <v-btn icon @click="updateTaskModal = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <!-- Update Task Form -->
      <v-form id="update_tasks_form" v-on:submit.prevent>
        <v-row class="add-task pr-0 assign-task-modal">
          <v-col
            cols="10"
            sm="11"
            lg="11"
            md="11"
            xl="11"
            class="mt-5 pt-4 name-inputfield"
          >
            <!-- Task Tile Text Field -->
            <v-row>
              <v-col cols="12">
                <v-text-field
                  autocomplete="off"
                  id="my-task"
                  v-on:keyup.enter="myblur()"
                  @blur="myblur()"
                  @focus="myfocus()"
                  name="task_name"
                  label="Enter a title for this task"
                  type="text"
                  v-model="form.title"
                  outlined
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- Project Name Text Field -->
            <v-row class="mt-n7">
              <v-col cols="12" md="6" style="padding-left: 9px">
                <p class="project-label">
                  Project :
                  <img
                    src="../../assets/images/task-add-project.svg"
                    @load="focusTitle()"
                  />
                </p>
                <v-text-field
                  autocomplete="off"
                  id="my-project"
                  name="task_name"
                  type="text"
                  onKeyDown="return false"
                  v-model="form.project_name"
                  required
                ></v-text-field>
              </v-col>

              <!-- Complete Checkbox  -->
              <v-col cols="12" md="6" style="padding-left: 20px" class="mt-6">
                <v-checkbox
                  v-model="form.completed"
                  label="Task Completed"
                  @change="update_status()"
                  color="success"
                  hide-details
                ></v-checkbox>
              </v-col>

              <!-- Date Range Picker -->
              <v-col
                cols="12"
                md="6"
                style="padding-left: 9px"
                class="mt-4 d-none"
              >
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="form.due_date"
                      label="Due Date"
                      persistent-hint
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      onKeyDown="return false"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.due_date"
                    no-title
                    @input="menu1 = false"
                    @change="dateChange"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <!-- Description Field Text Area -->
            <v-row class="mt-2">
              <v-col cols="12">
                <div class="form-group">
                  <v-textarea
                    outlined
                    id="textarea"
                    v-model="form.description"
                    @blur="myblurdesc()"
                    @focus="myfocusDesc()"
                    label="Add Description here"
                    rows="3"
                    max-rows="6"
                    maxlength="250"
                  ></v-textarea>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
export default {
  name: "UpdatetaskModal",
  computed: {
    ...mapState("custom", [
      "team_members",
      "selected_company",
      "projects_list",
      "task_members",
      "companies_list",
    ]),
    selectedCompanyUserStatus() {
      const company = this.companies_list.find(
        (c) => c.id === this.$route.params.id
      );
      if (company) return company.user_status;
      else {
        this.$store.dispatch("custom/redirectToCompaniesListing");
      }
    },
    active_projects() {
      return this.projects_list.active.filter(
        (project) => project.employees_detail.length > 0
      );
    },
  },
  data() {
    return {
      task: "",
      menu1: false,
      updateTaskModal: false,
      title_error: "",
      status_desc: true,
      members: [],
      update_title: "",
      update_description: "",
      update_date: "",
      form: {
        company_id: "",
        task_id: "",
        title: "",
        description: "",
        due_date: "",
        project_id: "",
        project_name: "",
        members: [],
        completed: "",
        status: "",
        update_title_text: "",
        update_decription_text: "",
      },
      options3: [],
      status_title: true,
      currentTask: "",
      updateMembers: false,
    };
  },
  watch: {},

  mounted() {
    /**
     * Custom event for update
     * task data in modal
     */
    this.$eventBus.$on("taskUpdater", (data) => {
      this.updateTaskModal = true;
      this.currentTask = data;
      this.task = data;
      this.form.title = data.title;
      this.form.update_title_text = data.title;
      this.form.description = data.description;
      this.form.update_decription_text = data.description;
      this.form.due_date = this.formatDate(data.due_date);
      this.form.task_id = data.id;
      this.form.project_id = data.project.id;
      (this.form.project_name = data.project_name),
        (this.form.completed = data.completed),
        (this.form.status = data.status);
      // this.$nextTick(() => {
      //   let members = [];
      //   if (this.task.project) {
      //     this.projects_list.active.forEach((project) => {
      //       if (
      //         project.id === this.task.project.id &&
      //         project.id !== "undefined"
      //       ) {
      //         project.employees_detail.forEach((member) => {
      //           members.push(member);
      //         });
      //       }
      //     });
      //   }
      //   this.options3 = members;
      // });
    });
  },

  beforeDestroy() {},
  methods: {
    /**
     * This function is resposible for
     * update due date
     */
    dateChange() {
      this.update_date = this.form.due_date;
      if (this.update_date) {
        this.changeDateUpdate();
      }
    },
    /**
     * This function is resposible for
     * call update description when blur
     * on text field
     */
    myblurdesc() {
      this.update_description = this.form.description;
      if (this.update_description !== this.form.update_decription_text) {
        this.changeDescriptionUpdate();
      } else {
        this.status_desc = true;
        return this.update_description;
      }
      this.status_desc = true;
    },

    myfocusDesc() {
      this.status_desc = false;
    },
    /**
     * This function is resposible for
     * update Title
     */
    changeTitleUpdate() {
      let data = {
        company_id: this.$route.params.id,
        task_id: this.form.task_id,
        update_title: this.form.title,
      };
      this.$store
        .dispatch("custom/updateTitle", data)
        .then((response) => {
          this.form.update_title_text = this.form.title;
          let payload_data = {
            task_id: this.form.task_id,
            title: this.form.update_title_text,
          };
          this.$eventBus.$emit("update_title", payload_data);
        })
        .catch((error) => {
          if (error.response.data.error === true) {
            this.form.title = this.form.update_title_text;
            this.title_error = error.response.data.errors.update_title[0];
          }
        });
    },
    /**
     * This function is resposible for
     * update Title (api call)
     */
    changeDescriptionUpdate() {
      let data = {
        company_id: this.$route.params.id,
        task_id: this.form.task_id,
        update_description: this.form.description,
      };
      this.$store
        .dispatch("custom/updateDescription", data)
        .then((response) => {
          this.form.update_decription_text = this.form.description;
          this.form.description = response.data.task.description;
          let payload_data = {
            task_id: this.form.task_id,
            description: this.form.description,
          };
          this.$eventBus.$emit("update_description", payload_data);
        })
        .catch((error) => {
          if (error.response.data.error === true) {
            this.form.description = this.form.update_decription_text;
            this.title_error = error.response.data.errors.update_title[0];
          }
        });
    },
    /**
     * This function is resposible for
     * update Due Date (api call)
     */
    changeDateUpdate() {
      let data = {
        company_id: this.$route.params.id,
        task_id: this.form.task_id,
        update_date: this.form.due_date
          ? this.formatDate(this.form.due_date)
          : "",
      };
      this.$store
        .dispatch("custom/updateDate", data)
        .then((response) => {
          this.form.due_date = response.data.task.due_date;
          let payload_data = {
            task_id: this.form.task_id,
            due_date: this.form.due_date,
          };
          this.$eventBus.$emit("update_due_date", payload_data);
        })
        .catch((error) => {});
    },
    /**
     * This function is resposible for
     * Custom event to update task
     * status
     */
    update_status() {
      this.$root.$emit("taskStatusUpdate", this.form.task_id);
    },

    focusTitle() {
      document.getElementById("my-task").focus();
    },
    /**
     * This function is resposible for
     * call update title when blur
     * on text field
     */
    myblur() {
      this.status_title = true;
      this.update_title = this.form.title;
      if (this.update_title !== this.form.update_title_text) {
        this.changeTitleUpdate();
      } else {
        return this.update_title;
      }
    },
    myfocus() {
      this.status_title = false;
      this.title_error = "";
    },
    /**
     * This function is resposible for
     * format the due date
     */
    formatDate(date) {
      if (date) {
        let d = new Date(date),
          month = "" + (d.getMonth() + 1),
          day = "" + d.getDate(),
          year = d.getFullYear();
        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
        return [year, month, day].join("-");
      }
    },
    /**
     * This function is resposible for
     * clear the data when modal is close
     */
    onHidden() {
      this.options3 = this.form.members = this.updateMembers = false;
      this.title_error = "";
    },
  },
};
</script>

<style scoped>
.reason-text {
  font-size: 13px;
  height: 78px;
  width: 100%;
  border-radius: 3px;
  border: none !important;
  padding-left: 10px;
  background-color: #f8f9fb;
}
.textarea-set {
  font-size: 12px;
  color: black;
  padding-top: 10px;
}
.desc-img {
  width: 14px;
  height: 15px;
}
.desc-label {
  font-size: 14px;
  font-family: "poppins";
  color: #000000;
  letter-spacing: 0.2px;
  text-align: left;
  line-height: 20px;
  margin-top: 30px !important;
}
.project-label {
  font-size: 11px;
  font-family: "Poppins";
  letter-spacing: normal;
  text-align: left;
  color: #88a1ca;
  cursor: pointer;
  margin-bottom: -4px;
  padding-top: 3px;
  padding-left: 6px;
}

.right {
  margin-left: 18rem !important;
  margin-top: -1.55rem;
}

.form-group label {
  margin-top: -7px !important;
}

form .error {
  background-color: transparent !important;
}

.add-task input:hover {
  border: none !important;
}

.add-task {
  padding-left: 25px !important;
}

.cursor-poniter:hover {
  cursor: pointer !important;
  transform: scale(1.01);
}

.update-task-modal {
  overflow: hidden !important;
}
</style>